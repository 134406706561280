<!--
 * @Description: 新装/加装任务项列表调度信息详情任务项
 * @Author: ChenXueLin
 * @Date: 2021-11-17 17:38:24
 * @LastEditTime: 2022-04-22 16:33:45
 * @LastEditors: LiangYiNing
-->
<template>
  <!-- 任务项 start -->
  <div class="task-list-content">
    <detail-table-title>
      <template v-slot:title>
        任务项
      </template>
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="sceneName">
            <el-input
              placeholder="场景名称"
              title="场景名称"
              v-model="searchForm.sceneName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="sceneType">
            <e6-vr-select
              v-model="searchForm.sceneType"
              :data="sceneTypeList"
              placeholder="场景类型"
              title="场景类型"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="engineerrpName">
            <el-input
              placeholder="工程师"
              title="工程师"
              v-model="searchForm.engineerrpName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="engineerrpPhone">
            <el-input
              placeholder="工程师电话"
              title="工程师电话"
              v-model="searchForm.engineerrpPhone"
            ></el-input>
          </el-form-item>

          <el-form-item class="search-item--1" prop="contactName">
            <el-input
              placeholder="安装联系人"
              title="安装联系人"
              v-model="searchForm.contactName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="contactPhone">
            <el-input
              placeholder="联系人电话"
              title="联系人电话"
              v-model="searchForm.contactPhone"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="address">
            <el-input
              placeholder="安装地址"
              title="安装地址"
              v-model="searchForm.address"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </detail-table-title>
    <div class="switch-page-button">
      <el-button
        round
        :class="[clickIndex == index ? 'activeButton' : '']"
        v-for="(btn, index) in btnList"
        :key="index"
        @click="switchPage(index, btn.statusNameCode)"
        >{{ btn.statusName }}{{ btn.count }}</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="380px"
      header-row-class-name="table-header"
      @expand-change="rowExpand"
      :row-key="getRowKeys"
      :expand-row-keys="expands"
      ref="multipleTable"
    >
      <!-- 展开行内容 -->
      <el-table-column type="expand">
        <!--  eslint-disable-next-line -->
        <template slot-scope="scope">
          <el-table
            v-loading="childListLoading"
            :data="childTableData"
            border
            header-row-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in expandedColumn"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="120">
        <template slot-scope="scope">
          <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import taskDetail from "@/mixins/taskDetail";
import {
  getInstallActionItem,
  installDetail,
  findDownList,
  getStatus
} from "@/api";
import { printError } from "@/utils/util";
import DetailTableTitle from "@/components/detailTableTitle.vue";
export default {
  name: "newAdd",
  components: {
    DetailTableTitle
  },
  data() {
    return {
      clickIndex: 0,
      expands: [],
      addColumn: [
        {
          display: true,
          fieldName: "itemId",
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "itemStatus",
          fieldLabel: "任务项状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "sceneName",
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "sceneType",
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "contactName",
          fieldLabel: "安装联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "contactPhone",
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "address",
          fieldLabel: "联系地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          display: true,
          fieldName: "scheduleId",
          fieldLabel: "调度任务ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "scheduleInfoId",
          fieldLabel: "调度信息ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "engineerName",
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "engineerPhone",
          fieldLabel: "工程师电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "assignor",
          fieldLabel: "指派人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "assignTime",
          fieldLabel: "指派时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      newAddColumn: [
        {
          display: true,
          fieldName: "itemId",
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "itemStatus",
          fieldLabel: "任务项状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "sceneName",
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "sceneType",
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "contactName",
          fieldLabel: "安装联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "contactPhone",
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "address",
          fieldLabel: "联系地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "installReq",
          fieldLabel: "安装要求",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "aircondition",
          fieldLabel: "空调",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "cooler",
          fieldLabel: "冷机",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "power",
          fieldLabel: "电源接入",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "dockingRequirements",
          fieldLabel: "对接要求",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "versionRequirements",
          fieldLabel: "版本要求",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          display: true,
          fieldName: "isCard",
          fieldLabel: "卡要求",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "hydraulic",
          fieldLabel: "液压",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "cutoilelec",
          fieldLabel: "断油断电",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "centerlock",
          fieldLabel: "中控锁",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "isMan",
          fieldLabel: "板卡控制",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "antennalocation",
          fieldLabel: "GPS天线位置",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "scheduleId",
          fieldLabel: "调度任务ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "scheduleInfoId",
          fieldLabel: "调度信息ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "engineerName",
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "engineerPhone",
          fieldLabel: "工程师电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "assignor",
          fieldLabel: "指派人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "assignTime",
          fieldLabel: "指派时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      expandedColumn: [
        {
          display: true,
          fieldName: "thirdClassName",
          fieldLabel: "商品名称",
          // width: 10,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "firstClassName",
          fieldLabel: "商品分类",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "secondClassName",
          fieldLabel: "商品开票名称",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipType",
          fieldLabel: "设备类型",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipCombo",
          fieldLabel: "设备组合",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "installStatus",
          fieldLabel: "安装状态",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "installDateStr",
          fieldLabel: "安装时间",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipCode",
          fieldLabel: "设备编号",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "simNo",
          fieldLabel: "SIM卡号",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "centerId",
          fieldLabel: "中心识别码",
          // width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      tableData: [], //
      searchForm: {
        itemStatus: "", //任务项状态
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        engineerrpName: "", //工程师名称
        engineerrpPhone: "", //工程师电话
        contactPhone: "", //联系人电话
        contactName: "", //联系人名称
        address: "", //安装地址
        pageIndex: 1,
        pageSize: 20
      },
      sceneTypeList: [], //场景类型
      total: 0,
      childTableData: [],
      childListLoading: false,
      //设置三级分类
      thirdClassVisible: false
    };
  },
  //任务单ID//客户ID    //页面类型
  props: [
    "taskId",
    "customerId",
    "scheduleInfoId",
    "basicInfo",
    "taskSecondClass"
  ],
  mixins: [taskDetail],
  computed: {
    columnData() {
      let data;
      if (this.taskSecondClass == 1) {
        data = this.newAddColumn;
      } else {
        data = this.addColumn;
      }
      return data;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.params.refresh) {
          this.getStatus();
          this.initData();
        }
      }
    }
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["sceneType"])];
        let [taskTypeRes] = await Promise.all(promiseList);
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
      this.getInstallActionItem();
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      this.getInstallActionItem();
      this.scrollTop = 0;
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getInstallActionItem();
    },
    //点击筛选框
    switchPage(index, itemStatus) {
      this.clickIndex = index;
      this.searchForm.itemStatus = itemStatus;
      this.getInstallActionItem();
      this.itemIdList = [];
      this.itemList = [];
      this.$refs.multipleTable.clearSelection();
    },
    //获取状态列表
    async getStatus() {
      if (!this.scheduleInfoId) {
        return;
      }
      try {
        let res = await getStatus({
          taskId: this.taskId,
          scheduleInfoId: this.scheduleInfoId
        });
        //基本信息
        this.btnList = this.getFreezeResponse(res, {
          path: "data"
        });
        this.searchForm.itemStatus =
          this.btnList && this.btnList.length
            ? this.btnList[0].statusNameCode
            : "";
        this.getInstallActionItem();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取新装加装任务单的任务项列表
    async getInstallActionItem() {
      if (!this.scheduleInfoId) {
        return;
      }
      try {
        let res = await getInstallActionItem({
          taskId: this.taskId,
          scheduleInfoId: this.scheduleInfoId,
          ...this.searchForm
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取安装明细
    async installDetail(itemId) {
      try {
        this.childListLoading = true;
        let res = await installDetail({
          itemId
        });
        //基本信息
        if (res.code == "OK") {
          this.childTableData = res.data;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
          });
          // //res.data;
          // this.childTableData.push({}); //触发vue更新视图
          // this.childTableData.pop(); //把最后添加的空对象删除掉
        }
      } catch (error) {
        printError(error);
      } finally {
        // this.childTableData = [{ thirdClassName: 1111 }];
        this.childListLoading = false;
      }
    },
    getRowKeys: function(row) {
      return row.itemId;
    },
    //点击行
    rowExpand(row, expandedRows) {
      //当行展示时调用接口
      if (expandedRows.length) {
        this.expands = [];
        if (row.itemId) {
          this.expands.push(row.itemId);
          this.childTableData = [];
          this.installDetail(row.itemId);
        }
      } else {
        this.expands = [];
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "任务项详情",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.itemId
          }
        });
      }
    },
    /******设置三级分类**********/
    setThirdClass() {
      this.thirdClassVisible = true;
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
      this.itemIdList = [];
      this.itemList = [];
      this.$refs.multipleTable.clearSelection();
    }
  },
  created() {
    this.getStatus();
    this.initData();
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-table__expanded-cell {
  padding: 0px;
}
</style>
