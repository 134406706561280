/*
 * @Description: 工单模块---任务单详情
 * @Author: ChenXueLin
 * @Date: 2021-11-18 11:08:33
 * @LastEditTime: 2023-03-20 15:47:38
 * @LastEditors: ChenXueLin
 */
import eventBus from "@/bus";
import {
  getFrameworkTree,
  closeTask,
  handleService,
  findDownList,
  associatedBills,
  getTaskItemEquip,
  closeService,
  batchReturnItem
} from "@/api";
import { printError } from "@/utils/util";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import base from "@/mixins/base";
export default {
  data() {
    return {
      /*****添加服务详情******/
      addServiceDialog: false,
      /*****添加跟进时间******/
      setTimeDialog: false,
      //   创建调度任务
      createDispatchDialog: false,
      // 指派更换工程师
      isChange: false,
      changeType: "assign",
      clickIndex: null,
      itemList: [], //勾选的任务项
      btnList: [], //状态列表
      itemIdList: [], //任务项id集合
      allUserList: [], //所有员工
      treeData: [], //部门集合
      sceneTypeList: [], //场景类型
      expands: [],
      serviceItemList: [],
      childTableData: [],
      expandedColumn: [
        {
          display: true,
          fieldName: "productName",
          fieldLabel: "产品名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "productNo",
          fieldLabel: "产品型号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipName",
          fieldLabel: "商品名称",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipType",
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "equipCode",
          fieldLabel: "设备编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "commids",
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      /******改约*******/
      changeTimeDialog: false,
      closeTaskItemDialog: false,
      batchReturnItemDialog: false
    };
  },
  components: {},
  computed: {
    selectData() {
      let data = this.treeData;
      if (this.handleForm.isDept == 0) {
        data = this.allUserList;
      }
      return data;
    }
  },
  mixins: [listPage, listPageReszie, base],
  methods: {
    //获取场景列表
    async getScene() {
      try {
        let res = await findDownList(["sceneType"]);
        this.sceneTypeList = res.data.sceneType;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取所有部门
    async getDepartment() {
      try {
        let res = await getFrameworkTree();
        if (res.code == "OK") {
          this.handleTreeData(res.data.children);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //全部员下拉框处理逻辑
    handleLoadEmployee: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val);
      }
    }, 300),
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //获取安装组合
    async installDetail(itemId) {
      try {
        this.childListLoading = true;
        let res = await getTaskItemEquip({
          itemId
        });
        //基本信息
        if (res.code == "OK") {
          this.childTableData = res.data;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.childListLoading = false;
      }
    },
    getRowKeys: function(row) {
      return row.itemId;
    },
    //点击行
    rowExpand(row, expandedRows) {
      //当行展示时调用接口
      if (expandedRows.length) {
        this.expands = [];
        if (row.itemId) {
          this.expands.push(row.itemId);
          this.childTableData = [];
          this.installDetail(row.itemId);
        }
      } else {
        this.expands = [];
      }
    },
    /*********关闭工单**********/
    //关闭工单
    closeTask(taskType = "工单") {
      this.$confirm(`是否确认关闭${taskType}？`, `关闭${taskType}`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (this.taskType == 1) {
            this.closeTaskReq();
          } else {
            this.closeServiceTaskReq();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //关闭任务单请求
    async closeTaskReq() {
      try {
        this.loading = true;
        let res = await closeTask({ taskIdList: [this.serviceId] });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.getServiceBasicInfoReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭服务单请求
    async closeServiceTaskReq() {
      try {
        this.loading = true;
        let res = await closeService({ serviceIds: [this.serviceId] });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.getServiceBasicInfoReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********添加服务详情*************/
    //重置
    handleReset() {
      this.$refs.searchForm.resetFields();
    },
    // 每页条数改变
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
    },
    //打开添加服务详情弹框
    addService() {
      this.addServiceDialog = true;
    },
    //获取关联单据
    async getAssociatedBills() {
      try {
        this.loading = true;
        let res = await associatedBills({
          taskId: this.serviceId
        });
        //基本信息
        this.jsonData = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******添加跟进时间*******/
    //点击添加跟进时间
    handleSetTime() {
      this.setTimeDialog = true;
    },
    /******我来处理+转单*************/
    async myHandle(reqType) {
      //reqType：1我来处理2转单
      try {
        let res = await handleService({
          serviceId: this.serviceId,
          handlerBy: this.turnForm.handlerBy
        });
        if (reqType == 2 && res.code == "OK") {
          this.$message.success("转单成功");
          this.turnOrderVisible = false;
          this.turnForm = {
            handlerBy: ""
          };
        }
        this.getServiceBasicInfoReq();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********任务项列表的一些操作mixins start**************/
    //全选
    selectAll(val) {
      if (val.length) {
        this.serviceItemList = [];
        eventBus.$emit("changeServiceItemList", this.serviceItemList);
      }
    },
    // 勾选任务项
    handleSelectionChange(columns) {
      this.itemIdList = columns.map(item => {
        return item.itemId;
      });
      this.itemList = columns;
      this.serviceItemList = columns.map(item => {
        return item.itemId;
      });
      eventBus.$emit("changeServiceItemList", this.serviceItemList);
    },

    /*********指派工程师**************/
    //获取下拉框数据
    async getData() {
      try {
        this.loading = true;
        let promiseList = [findDownList(["sceneType"])];
        let [sceneTypeRes] = await Promise.all(promiseList);
        //获取场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(sceneTypeRes, {
          path: "data.sceneType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击更换/指派工程师
    changeEngineer(changeType) {
      if (!this.itemIdList.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      if (
        !this.itemList.every(item => {
          return item.itemStatusCode == 1 || item.itemStatusCode == 2;
        })
      ) {
        this.$message.warning("只有未分配或待指派的任务项才能创建调度任务");
        return;
      }
      if (this.basicInfo.taskSource == 3 || this.basicInfo.taskSource == 5) {
        let firstItem = this.itemList[0];
        if (!this.itemList.every(item => item.address == firstItem.address)) {
          this.$message.warning("不同联系地址不能同时创建调度任务");
          return;
        }
        if (
          !this.itemList.every(
            item => item.contactName == firstItem.contactName
          )
        ) {
          this.$message.warning("不同联系人不能同时创建调度任务");
          return;
        }
      }
      if (changeType == "assgin") {
        //指派工程师
        if (
          !this.itemList.every(item => {
            return item.itemStatusCode == 1 || item.itemStatusCode == 2;
          })
        ) {
          this.$message.warning("只有未分配/待指派的任务项才能指派工程师");
          return;
        }
      } else {
        if (
          !this.itemList.every(item => {
            return item.itemStatusCode == 3 || item.itemStatusCode == 4;
          })
        ) {
          this.$message.warning("只有待接受/待作业的任务项才能更换工程师");
          return;
        }
      }
      this.changeType = changeType;
      this.isChange = true;
    },

    handleItem(type) {
      if (!this.itemIdList.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      if (type == 1) {
        //撤回工单
        // this.$confirm("是否确认撤回任务项？", "撤回任务项", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning"
        // })
        //   .then(() => {
        //     this.batchReturnItemReq();
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消"
        //     });
        //   });
        this.batchReturnItemDialog = true;
      }
      if (type == 2) {
        //关闭工单
        this.closeTaskItemDialog = true;
        // this.$confirm("是否确认关闭任务项？", "关闭任务项", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning"
        // })
        //   .then(() => {
        //     this.batchCloseItemReq();
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消"
        //     });
        //   });
      }
      if (type == 3) {
        //改约
        this.changeTimeDialog = true;
      }
    },
    /*********撤回工单**************/
    //撤回工单请求
    async batchReturnItemReq() {
      try {
        let res = await batchReturnItem({
          itemIdList: this.itemIdList
        });
        if (res.code == "OK") {
          this.$message.success("撤回成功");
          this.updateItemInfo();
          this.getStatus();
          this.itemIdList = [];
          this.$refs.multipleTable.clearSelection();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    updateItemInfo() {
      eventBus.$emit("refreshTaskDetail");
    },
    //创建调度任务
    addDispatch() {
      if (!this.itemIdList.length) {
        this.$message.warning("请先勾选任务项");
        return;
      }
      if (
        !this.itemList.every(item => {
          return item.itemStatusCode == 1 || item.itemStatusCode == 2;
        })
      ) {
        this.$message.warning("只有未分配或待指派的任务项才能创建调度任务");
        return;
      }
      if (this.basicInfo.taskSource == 3 || this.basicInfo.taskSource == 5) {
        let firstItem = this.itemList[0];
        if (!this.itemList.every(item => item.address == firstItem.address)) {
          this.$message.warning("不同联系地址不能同时创建调度任务");
          return;
        }
        if (
          !this.itemList.every(
            item => item.contactName == firstItem.contactName
          )
        ) {
          this.$message.warning("不同联系人不能同时创建调度任务");
          return;
        }
        if (
          !this.itemList.every(
            item => item.contactPhone == firstItem.contactPhone
          )
        ) {
          this.$message.warning("不同联系电话不能同时创建调度任务");
          return;
        }
      }
      this.createDispatchDialog = true;
    },
    /********任务项列表的一些操作mixins end**************/
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    }
  }
};
